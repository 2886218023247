
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import ApiSsy from "@/request/apis/api_ssy";

    interface IState {
        list: any,
        visible: any,
        title: any,
        total: any,
        searches: any
        type: any,
        formState:any,
        treeData:any,
        xuanxiangList:any
    }



    export default defineComponent({
        name: 'salaryRange',
        components: {
            MinusCircleOutlined,
            PlusOutlined,
        },
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                name: '',
                treeData:[],
                list: [],
                total: 0,
                xuanxiangList:[],
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                },
                type: '',
                formState:'' ,
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '模板名称',
                    dataIndex: 'title',
                    key: 'title',
                    align:'center'
                },
                {
                    title: '选项',
                    dataIndex: 'tplOps',
                    key: 'tplOps',
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    align:'center'
                },
                 {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    align:'center'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 200,
                    align:'center'
                },
            ]

            const rules = {
                title: [{required: true, message: "请输入模板名称"},],
                options: [{required: true, message: "请选择选项"},],
            }


            const add = () => {
                state.title = '新增模板'
                state.visible = true
                state.type = 1
                state.formState={
                    options:[],
                    title:''
                }
            }



            const edit = (item) => {
                state.type = 2
                state.title = "编辑模板"
                const val =JSON.parse(JSON.stringify(item))
                state.formState={
                    "id":val.id,
                    "title": val.title,
                    "options":[]
                }
                val.tplOps.forEach((value1,value2)=>{
                    state.formState.options.push(value1.option_id) 
                })
                state.visible = true
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiSsy.templateIndex(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.list
                    state.total = data.count
                }
            }

            const getoptionList = async () => {
                const params = {page:1,limit:999}
                const res = await ApiSsy.optionList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.xuanxiangList = data.list
                }
            }
            const formRef = ref();
            const onSubmit = async () => {
                formRef.value.validate() .then(async() => {
                        const params = {...state.formState}
                        params.status = 1
                        const res = await ApiSsy.templateStore(params)
                        if(res.error_code == 0){
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }


            const onDel = async (val) => {
                const params = {id:val.id}
                const res = await ApiSsy.templateDestroy(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        await getList()
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }
            
            const onStatus = async (val) => {
                const params = {id:val.id}
                const res = await ApiSsy.disableAndEnable(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        await getList()
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }
            onMounted(() => {
                getList()
                getoptionList()
            })

            return {
                ...toRefs(state),
                columns,
                open,
                getoptionList,
                formRef,
                onStatus,
                onSubmit,
                rules,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
